const AccordionData = [
    {
        title: "Common PayPal and credit card issues",
        content: "Lorem ipsum is simply dummy text of the printing type setting and industry. Lorem ipsum has been the industry's standard dummy."
    },
    {
        title: "Lets collaborate and make an impact business",
        content: "Lorem ipsum is simply dummy text of the printing type setting and industry. Lorem ipsum has been the industry's standard dummy."
    },
    {
        title: "A satisfied customer is the best business strats",
        content: "Lorem ipsum is simply dummy text of the printing type setting and industry. Lorem ipsum has been the industry's standard dummy."
    }
]

const AccordionData02 = [
    {
        title: "Aracımı kiralayarak para kazanmak için ne yapmalıyım?",
        content: "Mevcut aracınızı kiraya vermek için teklif formumuzu doldurmanız yeterli. Çalışanlarımız sizinle iletişime geçecektir. Aracınız yoksa, kiraya vermek üzere araç yatırımı yapmayı düşünebilirsiniz. En kârlı araç yatırımı için bütçenizi belirledikten sonra Folya Filo’ya ulaşabilirsiniz. Çalışanlarımız araba kiralama ve araç yatırımı yapma süreçleri boyunca tüm sorularınızı cevaplayacak ve size yardımcı olacaktır."
    },
    {
        title: "Araç yatırımı yapmak için ne kadar bütçeye ihtiyacım var?",
        content: "Araç yatırımı için ayırmanız gereken bütçe, gelir beklentinize ve araç kiralama kriterlerine uyan arabaların piyasa fiyatlarına göre değişiklik gösterir. Bu aşamada, deneyimli sektör uzmanlarımız size yardımcı olacaktır"
    },
    {
        title: "Arabamı kiraya vermek için hangi özelliklere sahip olmalı?",
        content: "Kiraya vermek için aracınız 3 yıldan eski ve 50.000 kilometrenin üzerinde olmamalı. Aracınızın marka, model, kilometre bilgilerini girerek olası aylık gelirinizi hesaplamak için çevrimiçi hesaplama motorumuzu kullanabilirsiniz."
    },
    {
        title: "Aracımı kiralamak için ne tür belgeler imzalayacağım?",
        content: "Sizinle Folya Filo Kiralama AŞ arasında araç kiralama sözleşmesi imzalanacaktır. "
    },
    {
        title: "Araç kiralama riskli midir?",
        content: "Folya Filo ile yapacağınız sözleşme, araç kiralama risklerini asgari düzeye indirir. Bu sözleşme, tarafların sorumluluklarını net şekilde belirler ve aracınızı kiralarken yaşayabileceğiniz riskleri neredeyse tamamen bertaraf eder."
    },
    {
        title: "Arabamı en az ve en fazla ne kadar süre kiraya verebilirim?",
        content: "Aracınızı en az 6 ay, en çok 3 yıl süreyle kiraya verebilirsiniz."
    },
    {
        title: "Aracımın bakım ve onarım masraflarını kim ödeyecek?",
        content: "Sözleşmeyle belirlenen araç bakım, lastik ve kasko bedelleri Folya Filo Kiralama AŞ tarafından ödenir."
    },
    {
        title: "Kiraladığım araçla ilgili ödemem gereken zorunluluklar var mı?",
        content: "Motorlu Taşıtlar Vergisi (MTV) ve araç sigorta bedeli araç sahibi tarafından ödenmelidir."
    },
    {
        title: "Trafik cezaları ve otoyol-köprü geçiş ücretleri kim tarafından ödenecek?",
        content: "Trafik cezaları ve otoyol ve köprü geçiş ücretleri sizin sorumluluğunuzda değildir."
    },
    {
        title: "Arabamı geri almak istersem ne yapmam gerekiyor?",
        content: "Sözleşmede belirtilen süreden önce aracınızı geri almak isterseniz, cayma bedeli ödemeniz gerekmektedir."
    },
    {
        title: "Arabamı kimler kiralayacak?",
        content: "Aracınız yalnızca kurumsal firmalara uzun dönem kiralanır. Folya Filo, bireysel ve günlük kiralamalar yapmamaktadır. "
    },
    {
        title: "Ofisiniz var mı? Ziyaret edebilir miyim?",
        content: "Ofisimiz mevcut. Randevu alarak ofisimizi ziyaret edebilirsiniz. Sizi ağırlamaktan memnuniyet duyarız."
    },
    {
        title: "Kiraladığım arabamla ilgili her şeyin yolunda olduğunu nasıl bilebilirim?",
        content: "Kiraya verdiğiniz araç, Folya Filo tarafından bir takip cihazıyla anbean izlenecektir. Gerekli durumlarda tarafınıza müşteri temsilcimiz tarafından anında bilgi verilecektir."
    },
    {
        title: "Kiralık verdiğim araç kaza yaparsa ne olacak?",
        content: "Olası kazalarda oluşacak zararlar kaskodan karşılanır. Aracınızın değer kaybına uğraması halinde ise zararınız tespit edilerek tarafımızca ödenir."
    },
    {
        title: "Arabam hangi firmalara kiralanacak?",
        content: "Araç kiralama için ticari ortaklık kurduğumuz firmalar küçük ve orta ölçekli olarak ayrılıyor. Bir firmadan araç kiralama talebi geldiğinde, firmanın talepleri Folya Filo tarafından değerlendiriliyor. Aracınız, talepleri karşıladığı ve uygun olduğu takdirde ilgili firmaya kiralanıyor."
    },
    {
        title: "İlk kira gelirimi ne zaman alacağım?",
        content: "Sözleşme tarihinden bir ay sonra ilk kira gelirinizi alacaksınız."
    },
    {
        title: "Param her ay düzenli olarak yatacak mı?",
        content: "Evet. Araç kiralama geliriniz her ay düzenli olarak Fola Filo Kiralama AŞ tarafından hesabınıza ödenecektir."
    },
    {
        title: "Ödenecek kira geliri her ay değişecek mi yoksa sabit mi kalacak?",
        content: "Sözleşme süresince ödenecek kira bedeli sabit kalır."
    },
    {
        title: "Folya Filo’ya güvenebilir miyim? Sektör tecrübeniz nedir?",
        content: "Tüm yöneticilerimiz ve çalışanlarımız 10 yıldır araç satın alma ve kiralama sektöründe çalışıyor.5 yıldır ise uzman kadromuzla araç kiralama hizmeti veriyoruz."
    }
]

const AccordionData05 = [
    {
        time: "06:00 - 07:00",
        title: 'Yoga and Pilates',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: 'By Edward watson'
    },
    {
        time: "08:00 - 09:00",
        title: 'Energy Blast',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: 'By Bryan jonhson'
    },
    {
        time: "10:00 - 11:00",
        title: 'Cardio Workout',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: 'By Jeremy dupont',

    },
    {
        time: "11:00 - 12:00",
        title: 'Athletics Classes',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: ' By Edward watson'
    },
    {
        time: "13:00 - 15:00",
        title: 'Boxercise',
        content: 'Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum.',
        author: ' By Bryan jonhson',

    }
]

export { AccordionData, AccordionData02, AccordionData05 };