// import React, { useState, useEffect } from 'react';
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Pagination, Navigation, EffectFade, Autoplay } from "swiper";
// import { LazyMotion, domMax, m } from 'framer-motion';
// import { Container, Row, Col } from 'react-bootstrap';
// import { Link } from 'react-router-dom';

// // Component
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import IconWithText from '../../../Components/IconWithText/IconWithText';
// import { IconWithTextData_03 } from '../../../Components/IconWithText/IconWithTextData';
// import { fadeIn } from '../../../Functions/GlobalAnimations';
// import { addContactInfo } from '../../Services/services';

// const StartupPageBannerSlider = () => {
//     const [activeSlide, setActiveSlide] = useState(0);
//     const [dialogOpen, setDialogOpen] = useState(false);
//     const [dialogConfirmationOpen, setDialogConfirmationOpen] = useState(false);
//     const [selectedName, setSelectedName] = useState('');
//     const [selectedEmail, setSelectedEmail] = useState('');
//     const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
//     const [isButtonDisabled, setIsButtonDisabled] = useState(true);

//     useEffect(() => {
//         const allFieldsFilled = selectedName && selectedPhoneNumber && selectedEmail;
//         setIsButtonDisabled(!allFieldsFilled);
//     }, [selectedName, selectedPhoneNumber, selectedEmail]);

//     const handleClickOpen = () => setDialogOpen(true);

//     const handleClose = () => setDialogOpen(false);

//     const onIstekBırakinClose = () => {
//         setDialogOpen(false);
//         setDialogConfirmationOpen(true);
//     };

//     const handleConfirmationClose = () => setDialogConfirmationOpen(false);

//     const handleContactWithMe = async () => {
//         onIstekBırakinClose();

//         const contactDetails = {
//             name: selectedName,
//             email: selectedEmail,
//             phoneNumber: selectedPhoneNumber,
//             isCommunicated: true,
//         };

//         await addContactInfo({ contactDetails });
//     };

//     const swiperData = [
//         {
//             img: "/assets/img/logo/bmw1.webp",
//             title: "En kârlı araç yatırımını yapın!",
//             subTitle: "Folya Filo ile araçlarınızı kiraya verin yatırımlarınız yollarda değer kazansın.",
//         }
//         // },
//         // {
//         //     img: "/assets/img/logo/bmw1.webp",
//         //     title: "En kârlı araç yatırımını yapın!",
//         //     subTitle: "Folya filo ile her ay düzenli gelir elde edin.",
//         // },
//     ];

//     return (
//         <section className="bg-darkgray home-startup-swiper">
//             <LazyMotion strict features={domMax}>
//                 <Swiper
//                     effect="fade"
//                     slidesPerView={1}
//                     spaceBetween={0}
//                     loop={true}
//                     navigation={true}
//                     pagination={true}
//                     autoplay={{ delay: 5000, disableOnInteraction: false }}
//                     fadeEffect={{ crossFade: true }}
//                     modules={[Pagination, Navigation, EffectFade, Autoplay]}
//                     onSlideChange={({ realIndex }) => setActiveSlide(realIndex)}
//                     breakpoints={{ 767: { pagination: false } }}
//                     className="startup swiper-navigation-04 swiper-navigation-light swiper-pagination-03 swiper-pagination-light swiper-pagination-medium sm-nav-hidden"
//                 >
//                     {swiperData.map((item, i) => (
//                         <SwiperSlide key={i} className="overflow-hidden">
//                             <m.div
//                                 initial={{ scale: 1.2 }}
//                                 animate={{ scale: activeSlide === i ? 1 : 1.2 }}
//                                 transition={{ duration: 1.7, ease: "easeInOut" }}
//                                 style={{ backgroundImage: `url(${item.img})` }}
//                                 className="overflow-hidden absolute h-full w-full top-0 left-0 cover-background"
//                             >
//                                 <img
//                                     src={item.img}
//                                     alt={item.title}
//                                     className="absolute h-full w-full object-cover"
//                                     loading="lazy"
//                                 />
//                             </m.div>
//                             <m.div className="opacity-50 absolute h-full w-full top-0 left-0 bg-darkgray"></m.div>
//                             <Container className="text-center">
//                                 <Row className="full-screen items-center justify-center md:landscape:h-[500px]">
//                                     <Col xs={12} lg={7} md={10} className="justify-center items-center my-0 mx-auto relative flex flex-col">
//                                         <m.h1
//                                             initial={{ clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }}
//                                             animate={{ clipPath: activeSlide === i ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' : 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }}
//                                             transition={{ duration: 0.5, delay: 0.5, ease: "easeIn" }}
//                                             className="font-serif font-semibold pb-[10px] text-[70px] tracking-[-2px] text-white mb-[35px] lg:text-[55px] lg:leading-[60px] xs:text-[35px] xs:leading-[40px] xs:mb-[20px]"
//                                         >
//                                             {item.title}
//                                         </m.h1>
//                                         <m.span
//                                             initial={{ clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }}
//                                             animate={{ clipPath: activeSlide === i ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' : 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }}
//                                             transition={{ duration: 0.5, delay: 0.8, ease: "easeIn" }}
//                                             className="font-serif block text-[19px] leading-[28px] mb-[35px] font-light text-white xs:text-base xs:mb-[20px]"
//                                         >
//                                             {item.subTitle}
//                                         </m.span>
//                                         <m.div
//                                             initial={{ clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }}
//                                             animate={{ clipPath: activeSlide === i ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' : 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }}
//                                             transition={{ duration: 0.5, delay: 0.9, ease: "easeIn" }}
//                                         >
//                                             <Link
//                                                 style={{ borderRadius: "16px", display: "flex", alignItems: "center" }}
//                                                 aria-label="iletisimegec"
//                                                 onClick={handleClickOpen}
//                                                 className="uppercase startup-link !bg-[#19876A] hover:!bg-[#19AA6A] font-medium"
//                                             >
//                                                 İLETİŞİME GEÇİN
//                                                 <div>
//                                                     <i className="fas fa-phone-alt mr-1"></i>
//                                                 </div>
//                                             </Link>
//                                             {/* Dialog for "Bir İstek Bırakın" */}
//                                             <Dialog open={dialogOpen} onClose={handleClose}>
//                                                 <Container className="flex flex-col w-fit bg-white p-4 rounded mt-4 md:!w-[70%] sm:!w-[85%] xs:!w-full">
//                                                     <Row className="justify-center">
//                                                         <Col className="text-center">
//                                                             <DialogTitle className='text-large'>Bir İstek Bırakın</DialogTitle>
//                                                             <DialogContentText>
//                                                                 Sorularınızı yanıtlamak için sizi 5 dakika içinde geri arayacağız.
//                                                             </DialogContentText>
//                                                         </Col>
//                                                     </Row>
//                                                     <DialogContent>
//                                                         <TextField
//                                                             margin="dense"
//                                                             id="name"
//                                                             label="Adınız"
//                                                             fullWidth
//                                                             onChange={(e) => setSelectedName(e.target.value)}
//                                                         />
//                                                         <TextField
//                                                             margin="dense"
//                                                             id="phone"
//                                                             label="Telefon Numaranız"
//                                                             type="phone"
//                                                             fullWidth
//                                                             onChange={(e) => setSelectedPhoneNumber(e.target.value)}
//                                                         />
//                                                         <TextField
//                                                             margin="dense"
//                                                             id="email"
//                                                             label="Email Adresiniz"
//                                                             type="email"
//                                                             fullWidth
//                                                             onChange={(e) => setSelectedEmail(e.target.value)}
//                                                         />
//                                                         <p className="text-center mt-2">Düğmeye tıklayarak gizlilik sözleşmesini kabul etmiş olursunuz.</p>
//                                                     </DialogContent>
//                                                     <DialogActions>
//                                                         <Button
//                                                             disabled={isButtonDisabled}
//                                                             className="!bg-green-800 text-white mx-[10px] font-large font-serif uppercase rounded !min-w-[200px] md:!mb-12 md:!w-full"
//                                                             size="lg"
//                                                             onClick={handleContactWithMe}
//                                                         >
//                                                             BİR İSTEK BIRAKIN
//                                                         </Button>
//                                                     </DialogActions>
//                                                 </Container>
//                                             </Dialog>
//                                         </m.div>
//                                     </Col>
//                                 </Row>
//                             </Container>
//                         </SwiperSlide>
//                     ))}
//                 </Swiper>
//             </LazyMotion>
//         </section>
//     );
// };

// export default StartupPageBannerSlider;
import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper";
import { LazyMotion, domMax, m } from 'framer-motion';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Component
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { addContactInfo } from '../../Services/services';

const StartupPageBannerSlider = () => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogConfirmationOpen, setDialogConfirmationOpen] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const [selectedEmail, setSelectedEmail] = useState('');
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [sliderLoaded, setSliderLoaded] = useState(false);
    const swiperRef = useRef(null);

    useEffect(() => {
        const allFieldsFilled = selectedName && selectedPhoneNumber && selectedEmail;
        setIsButtonDisabled(!allFieldsFilled);
    }, [selectedName, selectedPhoneNumber, selectedEmail]);

    const handleClickOpen = () => setDialogOpen(true);

    const handleClose = () => setDialogOpen(false);

    const onIstekBırakinClose = () => {
        setDialogOpen(false);
        setDialogConfirmationOpen(true);
    };

    const handleConfirmationClose = () => setDialogConfirmationOpen(false);

    const handleContactWithMe = async () => {
        onIstekBırakinClose();

        const contactDetails = {
            name: selectedName,
            email: selectedEmail,
            phoneNumber: selectedPhoneNumber,
            isCommunicated: true,
        };

        await addContactInfo({ contactDetails });
    };

    const swiperData = [
        // {
        //     img: "/assets/img/logo/bmw1.webp",
        //     title: "En kârlı araç yatırımını yapın!",
        //     subTitle: "Folya Filo ile araçlarınızı kiraya verin yatırımlarınız yollarda değer kazansın.",
        // },
        {
            img: "/assets/img/logo/bmw1.webp",
            title: "En kârlı araç yatırımını yapın!",
            subTitle: "Folya filo ile her ay düzenli gelir elde edin.",
        },
    ];

    // Slider'ın sadece bir kez yüklenmesini sağlamak için useEffect kullanıyoruz
    useEffect(() => {
        if (!sliderLoaded) {
            setSliderLoaded(true);
        }
    }, [sliderLoaded]);

    return (
        <section className="bg-darkgray home-startup-swiper">
            <LazyMotion strict features={domMax}>
                {sliderLoaded && (
                    <Swiper
                        ref={swiperRef}
                        effect="fade"
                        slidesPerView={1}
                        spaceBetween={0}
                        loop={true}
                        navigation={true}
                        pagination={true}
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        fadeEffect={{ crossFade: true }}
                        modules={[Pagination, Navigation, EffectFade, Autoplay]}
                        onSlideChange={({ realIndex }) => setActiveSlide(realIndex)}
                        breakpoints={{ 767: { pagination: false } }}
                        className="startup swiper-navigation-04 swiper-navigation-light swiper-pagination-03 swiper-pagination-light swiper-pagination-medium sm-nav-hidden"
                    >
                        {swiperData.map((item, i) => (
                            <SwiperSlide key={i} className="overflow-hidden">
                                <m.div
                                    initial={{ scale: 1.2 }}
                                    animate={{ scale: activeSlide === i ? 1 : 1.2 }}
                                    transition={{ duration: 1.7, ease: "easeInOut" }}
                                    style={{ backgroundImage: `url(${item.img})` }}
                                    className="overflow-hidden absolute h-full w-full top-0 left-0 cover-background"
                                >
                                    <img
                                        src={item.img}
                                        alt={item.title}
                                        className="absolute h-full w-full object-cover"
                                        loading="lazy"
                                    />
                                </m.div>
                                <m.div className="opacity-50 absolute h-full w-full top-0 left-0 bg-darkgray"></m.div>
                                <Container className="text-center">
                                    <Row className="full-screen items-center justify-center md:landscape:h-[500px]">
                                        <Col xs={12} lg={7} md={10} className="justify-center items-center my-0 mx-auto relative flex flex-col">
                                            <m.h1
                                                initial={{ clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }}
                                                animate={{ clipPath: activeSlide === i ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' : 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }}
                                                transition={{ duration: 0.5, delay: 0.5, ease: "easeIn" }}
                                                className="font-serif font-semibold pb-[10px] text-[70px] tracking-[-2px] text-white mb-[35px] lg:text-[55px] lg:leading-[60px] xs:text-[35px] xs:leading-[40px] xs:mb-[20px]"
                                            >
                                                {item.title}
                                            </m.h1>
                                            <m.span
                                                initial={{ clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }}
                                                animate={{ clipPath: activeSlide === i ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' : 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }}
                                                transition={{ duration: 0.5, delay: 0.8, ease: "easeIn" }}
                                                className="font-serif block text-[19px] leading-[28px] mb-[35px] font-light text-white xs:text-base xs:mb-[20px]"
                                            >
                                                {item.subTitle}
                                            </m.span>
                                            <m.div
                                                initial={{ clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }}
                                                animate={{ clipPath: activeSlide === i ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' : 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }}
                                                transition={{ duration: 0.5, delay: 0.9, ease: "easeIn" }}
                                            >
                                                <Link
                                                    style={{ borderRadius: "16px", display: "flex", alignItems: "center" }}
                                                    aria-label="iletisimegec"
                                                    onClick={handleClickOpen}
                                                    className="uppercase startup-link !bg-[#19876A] hover:!bg-[#19AA6A] font-medium"
                                                >
                                                    İLETİŞİME GEÇİN
                                                    <div>
                                                        <i className="fas fa-phone-alt mr-1"></i>
                                                    </div>
                                                </Link>
                                                {/* Dialog for "Bir İstek Bırakın" */}
                                                <Dialog open={dialogOpen} onClose={handleClose}>
                                                    <Container className="flex flex-col w-fit bg-white p-4 rounded mt-4 md:!w-[70%] sm:!w-[85%] xs:!w-full">
                                                        <Row className="justify-center">
                                                            <Col className="text-center">
                                                                <DialogTitle className='text-large'>Bir İstek Bırakın</DialogTitle>
                                                                <DialogContentText>
                                                                    Sorularınızı yanıtlamak için sizi 5 dakika içinde geri arayacağız.
                                                                </DialogContentText>
                                                            </Col>
                                                        </Row>
                                                        <DialogContent>
                                                            <TextField
                                                                margin="dense"
                                                                id="name"
                                                                label="Adınız"
                                                                fullWidth
                                                                onChange={(e) => setSelectedName(e.target.value)}
                                                            />
                                                            <TextField
                                                                margin="dense"
                                                                id="phone"
                                                                label="Telefon Numaranız"
                                                                type="phone"
                                                                fullWidth
                                                                onChange={(e) => setSelectedPhoneNumber(e.target.value)}
                                                            />
                                                            <TextField
                                                                margin="dense"
                                                                id="email"
                                                                label="Email Adresiniz"
                                                                type="email"
                                                                fullWidth
                                                                onChange={(e) => setSelectedEmail(e.target.value)}
                                                            />
                                                            <p className="text-center mt-2">Düğmeye tıklayarak gizlilik sözleşmesini kabul etmiş olursunuz.</p>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button
                                                                disabled={isButtonDisabled}
                                                                className="!bg-green-800 text-white mx-[10px] font-large font-serif uppercase rounded !min-w-[200px] md:!mb-12 md:!w-full"
                                                                size="lg"
                                                                onClick={handleContactWithMe}
                                                            >
                                                                BİR İSTEK BIRAKIN
                                                            </Button>
                                                        </DialogActions>
                                                    </Container>
                                                </Dialog>
                                            </m.div>
                                        </Col>
                                    </Row>
                                </Container>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </LazyMotion>
        </section>
    );
};

export default StartupPageBannerSlider;
