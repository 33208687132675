import React, { useRef, useState, useMemo, useEffect } from "react";

// Libraries
import { Link } from "react-router-dom";
import { Link as ScrollTo } from "react-scroll";

import { Col, Container, Navbar, Row } from "react-bootstrap";
import { m } from "framer-motion";
import TextField from "@mui/material/TextField";
import {
  Button as MUIButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chart from "react-apexcharts";
// Functions
import {
  fadeIn,
  fadeInLeft,
  fadeInRight,
} from "../../../Functions/GlobalAnimations";

// Components
import FooterMenu, { Footer } from "../../../Components/Footers/Footer";

// Data
import FooterData from "../../../Components/Footers/FooterData";

import Accordion from "../../../Components/Accordion/Accordion";
import { AccordionData02 } from "../../../Components/Accordion/AccordionData";
import GoogleMap from "../../../Components/GoogleMap/GoogleMap";

import IconWithText from "../../../Components/IconWithText/IconWithText";
import Header from "../../../Components/Header/Header";
import { HeaderNav } from "../../../Components/Header/Header";
import ProcessStep from "../../../Components/ProcessStep/ProcessStep";
import Overlap from "../../../Components/Overlap/Overlap";
import SideButtons from "../../../Components/SideButtons";
import { TiltBox } from "../../../Components/FancyText/FancyText";
import StartupPageBannerSlider from "./StartupBanner";
import {
  getCarDetails,
  makeCalculation,
  makeInvestmentCalculation,
  sendMail,
} from "../../Services/services";
import {
  IconWithTextData_02,
  IconWithTextData_03,
} from "../../../Components/IconWithText/IconWithTextData";
import { TestimonialsCarouselData2 } from "../../../Components/TestimonialCarousel/TestimonialsCarouselData";
import TestimonialsCarousel02 from "../../../Components/TestimonialCarousel/TestimonialsCarousel02";
import InViewPort from "../../../Components/InViewPort";
import Services from "../../../Components/Services/Services";

const iconWithTextData = [
  {
    icon: "line-icon-Cursor-Click2 text-[#19876A]",
    title: "Şeffaf ve güvenilir ortaklık",
    content: "",
  },
  {
    icon: "line-icon-Bakelite text-[#19876A]",
    title: "Düzenli gelir elde edin",
    content: "",
  },
  {
    icon: "line-icon-Boy text-[#19876A]",
    title: "Enflasyondan etkilenmeyin",
    content: "",
  },
];

const ProcessStepData = [
  {
    title: "Bütçe Belirleyin",
  },
  {
    title: "Sizin için karlı bir araç bulalım",
  },
  {
    title: "Aracın ödemesini yapın",
  },
  {
    title: "Kira geliri için fiyat teklifi paylaşalım",
  },
  {
    title: "Sözleşme yapalım",
  },
  {
    title: "Kira gelirinizin tadını çıkarın",
  },
];

const Footer_Data = [
  FooterData[0],
  FooterData[1],
  FooterData[4],
  FooterData[3],
];

const HomeStartupPage = (props) => {
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedClass, setSelectedClass] = useState();
  const [selectedModel, setSelectedModel] = useState();
  const [selectedGearType, setSelectedGearType] = useState();
  const [selectedFuelType, setSelectedFuelType] = useState();
  const [selectedBodyType, setSelectedBodyType] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedKilometer, setSelectedKilometer] = useState();
  const [carDetails, setCarDetails] = useState([]);
  const [chartDetail, setChartDetail] = useState([]);
  const [investmentAmount, setInvestmentAmount] = useState("0");
  const [isChecked, setIsChecked] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [taxNumber, setTaxNumber] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [dialogConfirmationOpen, setDialogConfirmationOpen] =
    React.useState(false);

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    email: "",
    il: "",
    ilce: "",
    vergiil: "",
    vergiilce: "",
    kiralamasuresi: "",
    kiralanacakaracsayisi: "",
    kiralanacakaracmarka: "",
    kiralanacakaracmodel: "",
    vergino: "",
    aracyillikkm: "",
    firmawebsitesi: "",
    not: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    email: "",
    il: "",
    ilce: "",
    vergiil: "",
    vergiilce: "",
    kiralamasuresi: "",
    kiralanacakaracsayisi: "",
    kiralanacakaracmarka: "",
    kiralanacakaracmodel: "",
    vergino: "",
    aracyillikkm: "",
  });

  const handleChangex = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Error mesajını temizle
    if (value.trim() !== "") {
      setErrorMessages((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    // Zorunlu olan alanların listesi
    const requiredFields = [
      "name",
      "surname",
      "phoneNumber",
      "email",
      "il",
      "ilce",
      "vergiil",
      "vergiilce",
      "kiralamasuresi",
      "kiralanacakaracsayisi",
      "kiralanacakaracmarka",
      "kiralanacakaracmodel",
      "vergino",
      "aracyillikkm",
    ];

    // Yalnızca zorunlu alanları kontrol et
    requiredFields.forEach((key) => {
      if (!formData[key].trim()) {
        isValid = false;
        errors[key] = "Zorunlu alanları lütfen doldurunuz.";
      }
    });

    setErrorMessages(errors);
    return isValid;
  };

  const handleSubmit = async () => {
    debugger;
    if (validateForm()) {
      const res = await sendMail(formData);
      console.log("Form başarıyla gönderildi");
      setDialogConfirmationOpen(true);
    } else {
      console.log("Formda eksik alanlar var");
    }
  };

  const handleTaxNumberChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, "").slice(0, 9); // Sadece sayıları kabul et ve maksimum uzunluğu 9 karakterle sınırla
    setTaxNumber(value);
    if (error && value.length === 9) {
      // Eğer hata mesajı gösteriliyor ve uzunluk 9 karaktere ulaştıysa, hatayı kaldır
      setError(false);
      setHelperText("");
    }
  };

  const itemsPerPage = 5;
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
  };

  // Yatırım grafik detayları
  const investmenetChartDetails = {
    options: {
      colors: ["#19876A"],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: ["1 Ay", "1 Yıl", "3 Yıl", "5 Yıl"],
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            // Küçük değerler için boş döndür
            if (val < 1000) {
              return "";
            }
            return formatCurrency(val);
          }
        },
        // min ve max değerler belirleyerek uygun aralığı ayarlayabilirsiniz
        min: 0,
        // max: 1000000 // Gerekirse en yüksek değeri de belirtebilirsiniz
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      stroke: {
        curve: "smooth",
      },
      fill: {
        colors: ["#19876A", "#8BC34A", "#CDDC39"],
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return formatCurrency(val);
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return formatCurrency(val);
          }
        }
      }
    },
    series: [
      {
        name: "Kazanç",
        data: chartDetail, // Burada ham sayılar kullanılıyor
      },
    ],
  };
  const modelRef = useRef(null);
  const classRef = useRef(null);

  const classOptions = useMemo(() => {
    return selectedBrand?.classes || [];
  }, [selectedBrand]);

  const modelOptions = useMemo(() => {
    return selectedClass?.models || [];
  }, [selectedClass]);

  //calculate
  const handleCalculation = async () => {
    const carDetails = {
      modelId: selectedModel["modelid"],
      fuelTypeId: selectedFuelType,
      bodyTypeId: selectedBodyType,
      gearTypeId: selectedGearType,
      yearId: selectedYear,
      kilometerId: selectedKilometer,
    };

    const res = await makeCalculation({
      carDetails,
    });
    if (res.data.rentalFee == -1) {
      document.getElementById("labelResult").innerText =
        "Aracınız kiralık olarak kabul edilmemektedir.";
      document.getElementById("result").innerText = "";
    } else if (res.data.rentalFee == -2) {
      document.getElementById("labelResult").innerText =
        "Aracınızın kilometre kriteri uyuşmamaktadır.";
      document.getElementById("result").innerText = "";
    } else {
      // Önce sayısal değerleri hesaplayın
      var fee1 = res.data.rentalFee * 0.65;
      var fee2 = res.data.rentalFee * 0.75;

      // Sonra bu değerleri istediğiniz formata dönüştürün
      var formattedFee1 = fee1.toLocaleString("tr-TR", {
        useGrouping: true,
        minimumFractionDigits: 0,
      });
      var formattedFee2 = fee2.toLocaleString("tr-TR", {
        useGrouping: true,
        minimumFractionDigits: 0,
      });

      // Son olarak "₺" simgesini ekleyin ve sonuçları birleştirin
      var result1 = "₺" + formattedFee1;
      var result2 = "₺" + formattedFee2;

      document.getElementById("result").innerText = result1 + " - " + result2;
      document.getElementById("labelResult").innerText =
        "Aylık Tahmini Geliriniz";
    }
  };

  //calculate
  const handleCalculationForCompany = async () => {
    const carDetails = {
      modelId: selectedModel["modelid"],
      fuelTypeId: selectedFuelType,
      bodyTypeId: selectedBodyType,
      gearTypeId: selectedGearType,
      yearId: selectedYear,
      kilometerId: selectedKilometer,
    };

    const res = await makeCalculation({
      carDetails,
    });
    if (res.data.rentalFee == -1) {
      document.getElementById("labelResult").innerText =
        "Aracınız kiralık olarak kabul edilmemektedir.";
      document.getElementById("result").innerText = "";
    } else if (res.data.rentalFee == -2) {
      document.getElementById("labelResult").innerText =
        "Aracınızın kilometre kriteri uyuşmamaktadır.";
      document.getElementById("result").innerText = "";
    } else {
      // Önce sayısal değerleri hesaplayın
      var fee1 = res.data.rentalFee * 0.65 * 1.4;
      var fee2 = res.data.rentalFee * 0.75 * 1.4;

      // Sonra bu değerleri istediğiniz formata dönüştürün
      var formattedFee1 = fee1.toLocaleString("tr-TR", {
        useGrouping: true,
        minimumFractionDigits: 0,
      });
      var formattedFee2 = fee2.toLocaleString("tr-TR", {
        useGrouping: true,
        minimumFractionDigits: 0,
      });

      // Son olarak "₺" simgesini ekleyin ve sonuçları birleştirin
      var result1 = "₺" + formattedFee1;
      var result2 = "₺" + formattedFee2;

      document.getElementById("result").innerText = result1 + " - " + result2;
      document.getElementById("labelResult").innerText =
        "Aylık Tahmini Geliriniz";
    }
  };

  // Para birimi formatında göstermek için fonksiyon
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 0
    }).format(amount);
  };

  // Yatırım hesaplama fonksiyonu
  const handleInvestmentCalculation = async () => {
    const res = await makeInvestmentCalculation(investmentAmount);

    const resultElement = document.getElementById("yatirim-sonuc");
    if (res.data.investmentId === -1) {
      resultElement.innerText = "*Yatırım tutarına ait sonuç bulunamadı.";
      resultElement.style.color = "red"; // Metni kırmızı yap
      const dataToPass = [];
      setChartDetail(dataToPass);
    } else {
      document.getElementById("yatirim-sonuc").innerText = "";

      // Grafik verileri için ham sayılar
      const rawData = [
        res.data.earningHigh,
        res.data.earningHigh * 12,
        res.data.earningHigh * 36
      ];

      setChartDetail(rawData); // Grafik için ham sayılar kullanılıyor
    }
  };



  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const onIstekBırakinClose = () => {
    setDialogOpen(false);
    setDialogConfirmationOpen(true);
  };
  const handleConfirmationClickOpen = () => {
    setDialogConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setDialogConfirmationOpen(false);
    setDialogOpen(false);
  };

  const handleContactWithMe = async () => {
    onIstekBırakinClose();
  };

  const handleInvestmentCalculationForCompany = async () => {
    const res = await makeInvestmentCalculation(investmentAmount);
    const resultElement = document.getElementById("yatirim-sonuc");
    if (res.data.investmentId === -1) {
      resultElement.innerText = "*Yatırım tutarına ait sonuç bulunamadı.";
      resultElement.style.color = "red"; // Metni kırmızı yap
      const dataToPass = [];
      setChartDetail(dataToPass);
    } else {
      document.getElementById("yatirim-sonuc").innerText = "";
      const fee = parseFloat((res.data.earningHigh * 1.4).toFixed(2)); // 2 ondalık basamakla yuvarlama
      const dataToPass = [
        fee,
        parseFloat((fee * 12).toFixed(2)), // Yıl bazında hesaplamaları da yuvarlayarak sorunu çözebiliriz
        parseFloat((fee * 36).toFixed(2))
      ];
      setChartDetail(dataToPass);
      debugger;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCarDetails();
        setCarDetails(response.data); // Assuming the API response is an object with a 'data' property
      } catch (error) {
        console.error("Error fetching car details:", error);
      }
    };

    fetchData();
  }, []);

  // Function to convert API data to the structure of brandModel
  const convertToBrandModel = (apiData) => {
    return apiData.map((brand) => ({
      brandid: brand.id,
      brand: brand.label,
      label: brand.label,
      classes: brand.classes.map((carClass) => ({
        classid: carClass.id,
        name: carClass.label,
        label: carClass.label,
        models: carClass.models.map((model) => ({
          modelid: model.id,
          name: model.label,
          label: model.label,
        })),
      })),
    }));
  };

  const brandModelFromApi = convertToBrandModel(carDetails);

  return (
    <div style={props.style}>
      {/* Header Start */}
      <Header topSpace={{ md: true }} type="reverse-scroll ">
        <HeaderNav
          fluid="fluid"
          theme="dark"
          expand="lg"
          className="py-[0px] px-[35px] md:px-[15px] md:py-[20px] sm:px-0"
        >
          <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  width="111"
                  height="111"
                  src="/assets/img/logo/logo.png"
                  data-rjs="/assets/img/logo/logo.png"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  width="111"
                  height="36"
                  src="/assets/img/logo/logo.png"
                  data-rjs="/assets/img/logo/logo.png"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  src="/assets/img/logo/logo.png"
                  data-rjs="/assets/img/logo/logo.png"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle className="order-last md:ml-[17px] w-[25px] min-h-[15px] inline-block align-middle">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          {/* <Navbar.Collapse className="justify-center col-auto col-lg-8">
            <Menu {...props} />
          </Navbar.Collapse> */}
          <Navbar.Collapse className="col-auto col-lg-8 px-lg-0 mx-auto justify-center">
            <ul className="navbar-nav alt-font text-white">
              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="avantajlar"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  Avantajlar
                </ScrollTo>
              </li>
              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="nasil-calisir"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  Araç Yatırımı
                </ScrollTo>
              </li>
              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="aracimi-kiraya-ver"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  Aracımı kiraya ver
                </ScrollTo>
              </li>
              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="arac-kirala"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  Kurumsal Araç Kiralama
                </ScrollTo>
              </li>
              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="sikca-sorulan-sorular"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  Sıkça Sorulan Sorular
                </ScrollTo>
              </li>
              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="hakkimizda"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  Hakkımızda
                </ScrollTo>
              </li>
              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="iletisim"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  İletişim
                </ScrollTo>
              </li>
            </ul>
          </Navbar.Collapse>
          <Col
            lg={2}
            xs={"auto"}
            className="justify-end pe-0 flex items-center"
          ></Col>
        </HeaderNav>
      </Header>

      {/* Header End */}

      <SideButtons />

      {/* Section Start */}
      <StartupPageBannerSlider />
      {/* Section End */}
      <InViewPort>
        {/* Section Start */}
        <m.section
          className="bg-white py-[120px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
          {...fadeIn}
        >
          <Container>
            <Row className="justify-center">
              <Col md={12} className="text-center mb-[7%]">
                <h2 className="heading-3 font-serif font-semibold text-darkgray inline-block text-center mb-[25px] xs:mb-[30px]">
                  Araç kiralama ile
                </h2>
                <span>
                  <h2 className="heading-3 font-serif font-semibold text-[#19876A] inline-block text-center mb-[25px] xs:mb-[30px]">
                    &nbsp;gelir elde edin
                  </h2>
                </span>
              </Col>
            </Row>
            {/* <Services
            grid="row-cols-1 row-cols-lg-3 row-cols-md-2 gap-y-10 justify-center"
            theme="service-style-02"
            className=""
            data={serviceData2}
            animation={fadeIn}
          /> */}
            <div class="row-cols-1 row-cols-lg-3 row-cols-md-2 gap-y-10 justify-center row">
              <div class="col px-[15px]">
                <div class="service-style-02">
                  <div class="img-wrapper">
                    <img
                      height="238"
                      width="360"
                      class="w-full max-w-full align-middle"
                      src="/assets/img/services/1.webp"
                      alt="service-style-5"
                    />
                  </div>
                  <div class="service-style">
                    <span class="title font-medium text-darkgray block font-serif mb-[10px]">
                      Karlı bir araca yatırım mı yapmak istiyorsunuz?
                    </span>
                    <p>
                      Araç kiralama ile para kazanmak için öncelikle bütçenize
                      karar verin, çevrimiçi hesaplama motorumuzu kullanarak
                      olası gelirinizi öğrenin, Folya Filo olarak sizin için en
                      karlı araçları bulalım.
                    </p>
                    <div class="info-service">
                      <a
                        aria-label="services"
                        class="no-underline font-serif font-medium text-gray-900 text-sm uppercase block"
                        href="\"
                      >
                        <ScrollTo
                          to="aracim-yatirimi"
                          offset={80}
                          delay={0}
                          spy={true}
                          smooth={true}
                          duration={800}
                          className="inner-link nav-link"
                        >
                          BÜTÇE BELİRLE
                        </ScrollTo>

                        <ScrollTo
                          to="aracim-yatirimi"
                          offset={80}
                          delay={0}
                          spy={true}
                          smooth={true}
                          duration={800}
                          className="inner-link nav-link"
                        >
                          <i class="ti-arrow-right"></i>
                        </ScrollTo>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col px-[15px]">
                <div class="service-style-02">
                  <div class="img-wrapper">
                    <img
                      height="238"
                      width="360"
                      class="w-full max-w-full align-middle"
                      src="/assets/img/services/rsz_istock-1086877428.jpg"
                      alt="service-style-5"
                    />
                  </div>
                  <div class="service-style">
                    <span class="title font-medium text-darkgray block font-serif mb-[10px]">
                      Aracınız var ve kiraya mı vermek istiyorsunuz?
                    </span>
                    <p>
                      Aracınızın bilgilerini girin ve araç kiralama sürecinde
                      elde edeceğiniz tahmini gelirinizi öğrenin. Folya Filo ile
                      düzenli gelirinizin tadını çıkarın.
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                    <div class="info-service">
                      <a
                        aria-label="services"
                        class="no-underline font-serif font-medium text-gray-900 text-sm uppercase block"
                        href="/"
                      >
                        <ScrollTo
                          to="aracimi-kiraya-ver"
                          offset={80}
                          delay={0}
                          spy={true}
                          smooth={true}
                          duration={800}
                          className="inner-link nav-link"
                        >
                          ARACIMI KİRAYA VER
                        </ScrollTo>

                        <ScrollTo
                          to="aracimi-kiraya-ver"
                          offset={80}
                          delay={0}
                          spy={true}
                          smooth={true}
                          duration={800}
                          className="inner-link nav-link"
                        >
                          <i class="ti-arrow-right"></i>
                        </ScrollTo>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col px-[15px]">
                <div class="service-style-02">
                  <div class="img-wrapper">
                    <img
                      height="238"
                      width="360"
                      class="w-full max-w-full align-middle"
                      src="/assets/img/services/support.webp"
                      alt="service-style-5"
                    />
                  </div>
                  <div class="service-style">
                    <span class="title font-medium text-darkgray block font-serif mb-[10px]">
                      Sorularınız için burdayız.
                    </span>
                    <p>
                      Tüm yatırım sürecinde yanınızda olarak sizlere danışmanlık
                      hizmeti sunmaktayız. Karlı bir yatırımın parçası olmak
                      için hemen iletişime geçin.
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                    <div class="info-service">
                      <a
                        aria-label="info-service"
                        class="no-underline font-serif font-medium text-gray-900 text-sm uppercase block"
                        href="/"
                      >
                        <ScrollTo
                          to="iletisim"
                          offset={80}
                          delay={0}
                          spy={true}
                          smooth={true}
                          duration={800}
                          className="inner-link nav-link"
                        >
                          İLETİŞİM
                        </ScrollTo>
                        <ScrollTo
                          to="iletisim"
                          offset={80}
                          delay={0}
                          spy={true}
                          smooth={true}
                          duration={800}
                          className="inner-link nav-link"
                        >
                          <i class="ti-arrow-right"></i>
                        </ScrollTo>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </m.section>
        {/* Section End */}

        {/* Section Start */}
        <m.section
          id="arac-kirala"
          className="py-[140px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] relative bg-cover bg-center"
          style={{ backgroundImage: "url(/assets/img/logo/uzundonem.webp)" }}
        >
          <div className="absolute top-0 left-0 w-full h-full opacity-70 bg-darkslateblue"></div>
          <Container className="relative">
            <Row className="justify-center">
              <Col xl={6} lg={7} className="text-center mb-[4.5rem] md:mb-12">
                <h4 className="font-serif font-semibold text-white">
                  Kurumsal Yol Arkadaşınız
                </h4>
                <h5 className="font-serif mb-[5px] -tracking-[.5px] text-white block">
                  Uzun Dönem Araç Kiralamanın Güvenilir Adresi!
                </h5>

                <MUIButton
                  className="!bg-[#19876A] hover:!bg-[#19AA6A] !text-white !mt-8 !ml-2 !mr-2 mx-[10px] font-medium font-serif uppercase rounded !min-w-[200px] !min-h-[60px] !text-xl !py-4 !px-8 border-[2px] border-solid md:!mb-12 md:!mt-8 md:!w-full @media (max-width: 768px) { md:!w-3/4 }"
                  size="lg"
                  onClick={handleClickOpen}
                  variant="outlined"
                >
                  TEKLİF AL
                </MUIButton>
              </Col>
            </Row>
          </Container>
          <Dialog
            open={dialogOpen}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
          >
            {/* <Container className="flex flex-col w-fit !place-content-evenly !justify-center !items-center bg-white p-4 rounded mt-4 md:!w-[70%] sm:!w-[85%] xs:!w-full xss:!w-full "> */}
            <Container className="flex flex-col w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] 2xl:w-[50%] !place-content-evenly !justify-center !items-center bg-white p-4 rounded mt-4">
              <Row className="justify-center">
                <Col className="text-center">
                  <DialogTitle className="text-large">
                    {" "}
                    Yetkili Kişi Bilgileri
                  </DialogTitle>
                  <DialogContentText>
                    Aşağıdaki yetkili kişi bilgilerini eksiksiz doldurunuz.
                  </DialogContentText>
                </Col>
              </Row>
              <DialogContent>
                <div>
                  <Grid container spacing={3}>
                    {/* İsim */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="name"
                        name="name"
                        label="Adınız"
                        fullWidth
                        variant="outlined"
                        value={formData.name}
                        onChange={handleChangex}
                        helperText={errorMessages.name}
                        error={!!errorMessages.name}
                      />
                    </Grid>

                    {/* Soyad */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="surname"
                        name="surname"
                        label="Soyadınız"
                        fullWidth
                        variant="outlined"
                        value={formData.surname}
                        onChange={handleChangex}
                        helperText={errorMessages.surname}
                        error={!!errorMessages.surname}
                      />
                    </Grid>

                    {/* Telefon Numarası */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Telefon Numaranız"
                        fullWidth
                        variant="outlined"
                        value={formData.phoneNumber}
                        onChange={handleChangex}
                        helperText={errorMessages.phoneNumber}
                        error={!!errorMessages.phoneNumber}
                      />
                    </Grid>

                    {/* E-Posta */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="email"
                        name="email"
                        label="E-Postanız"
                        fullWidth
                        variant="outlined"
                        value={formData.email}
                        onChange={handleChangex}
                        helperText={errorMessages.email}
                        error={!!errorMessages.email}
                      />
                    </Grid>
                  </Grid>
                  <div>
                    <Row className="justify-center mb-4">
                      <Col className="text-center">
                        <DialogTitle className="text-large">
                          {" "}
                          Şirket Bilgileri*
                        </DialogTitle>
                        <DialogContentText>
                          Uzun dönem araç kiralaması yapmak için lütfen
                          aşağıdaki şirket bilgilerini eksiksiz doldurunuz.
                        </DialogContentText>
                      </Col>
                    </Row>

                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="il"
                          name="il"
                          label="İl"
                          fullWidth
                          variant="outlined"
                          value={formData.il}
                          onChange={handleChangex}
                          helperText={errorMessages.il}
                          error={!!errorMessages.il}
                          placeholder="İl yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="ilce"
                          name="ilce"
                          label="İlce"
                          fullWidth
                          variant="outlined"
                          value={formData.ilce}
                          onChange={handleChangex}
                          helperText={errorMessages.ilce}
                          error={!!errorMessages.ilce}
                          placeholder="İlçe yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          id="firmawebsitesi"
                          name="firmawebsitesi"
                          label="Firma Web Sitesi"
                          value={formData.firmawebsitesi}
                          onChange={handleChangex} // Bu satırı ekleyin
                          variant="outlined"
                          fullWidth
                          placeholder="Web sitenizi yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="vergiil"
                          name="vergiil"
                          label="Vergi Dairesi İl"
                          fullWidth
                          variant="outlined"
                          value={formData.vergiil}
                          onChange={handleChangex}
                          helperText={errorMessages.vergiil}
                          error={!!errorMessages.vergiil}
                          placeholder="Vergi Daire İl yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="vergiilce"
                          name="vergiilce"
                          label="Vergi Dairesi İlçe"
                          fullWidth
                          variant="outlined"
                          value={formData.vergiilce}
                          onChange={handleChangex}
                          helperText={errorMessages.vergiilce}
                          error={!!errorMessages.vergiilce}
                          placeholder="Vergi Daire İlçe yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        {/* <TextField
                          label="Firma Web Sitesi"
                          variant="outlined"
                          fullWidth
                          placeholder="Web sitenizi yazınız"
                        /> */}
                        <TextField
                          id="vergino"
                          name="vergino"
                          label="Vergi Numarası*"
                          variant="outlined"
                          fullWidth
                          value={formData.vergino}
                          onChange={handleChangex}
                          helperText={errorMessages.vergino}
                          error={!!errorMessages.vergino}
                          placeholder="_ _ _ _ _ _ _ _ _ _"
                          inputProps={{ maxLength: 9 }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Row className="justify-center mb-4">
                      <Col className="text-center">
                        <DialogTitle className="text-large">
                          {" "}
                          Araç Bilgileri*
                        </DialogTitle>
                        <DialogContentText>
                          Teklif almak istediğiniz araç bilgilerini eksiksiz
                          doldurunuz.
                        </DialogContentText>
                      </Col>
                    </Row>

                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="kiralamasuresi"
                          name="kiralamasuresi"
                          label="Kiralama Süresi"
                          fullWidth
                          variant="outlined"
                          value={formData.kiralamasuresi}
                          onChange={handleChangex}
                          helperText={errorMessages.kiralamasuresi}
                          error={!!errorMessages.kiralamasuresi}
                          placeholder="Kiralama Süresi yazınız, Örn: 12 Ay"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="kiralanacakaracsayisi"
                          name="kiralanacakaracsayisi"
                          label="Kiralanacak Araç Sayısı"
                          fullWidth
                          variant="outlined"
                          value={formData.kiralanacakaracsayisi}
                          onChange={handleChangex}
                          helperText={errorMessages.kiralanacakaracsayisi}
                          error={!!errorMessages.kiralanacakaracsayisi}
                          placeholder="Kiralanacak Araç Sayısı yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Araç Markası"
                          required
                          id="kiralanacakaracmarka"
                          name="kiralanacakaracmarka"
                          fullWidth
                          variant="outlined"
                          value={formData.kiralanacakaracmarka}
                          onChange={handleChangex}
                          helperText={errorMessages.kiralanacakaracmarka}
                          error={!!errorMessages.kiralanacakaracmarka}
                          placeholder="Araç Markası Yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Araç Modeli"
                          required
                          id="kiralanacakaracmodel"
                          name="kiralanacakaracmodel"
                          fullWidth
                          variant="outlined"
                          value={formData.kiralanacakaracmodel}
                          onChange={handleChangex}
                          helperText={errorMessages.kiralanacakaracmodel}
                          error={!!errorMessages.kiralanacakaracmodel}
                          placeholder="Araç Modeli Yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="aracyillikkm"
                          name="aracyillikkm"
                          value={formData.aracyillikkm}
                          helperText={errorMessages.aracyillikkm}
                          error={!!errorMessages.aracyillikkm}
                          onChange={handleChangex} // Bu satırı ekleyin
                          label="Yıllık KM Limiti"
                          variant="outlined"
                          fullWidth
                          placeholder="Yıllık KM Yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          id="not"
                          name="not"
                          value={formData.not}
                          onChange={handleChangex} // Bu satırı ekleyin
                          label="Not"
                          variant="outlined"
                          fullWidth
                          placeholder="Not Yazınız"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <p className="text-center mt-2">
                  Butona tıklayarak gizlilik sözleşmesini kabul etmiş olursunuz.
                </p>
              </DialogContent>
              <DialogActions>
                <MUIButton
                  className="disabled:!bg-gray-300 disabled:!text-gray-600 disabled:!border-gray-400 !bg-green-800 text-white mx-[10px] font-large font-serif uppercase rounded !min-w-[200px] md:!mb-12  md:!w-full"
                  size="large"
                  onClick={handleSubmit}
                >
                  TEKLİF AL
                </MUIButton>
              </DialogActions>
            </Container>
          </Dialog>
          <Dialog
            open={errorDialogOpen}
            onClose={() => setErrorDialogOpen(false)}
          >
            <DialogTitle>Hata</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Lütfen tüm zorunlu alanları doldurunuz.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={() => setErrorDialogOpen(false)} color="primary">
                Tamam
              </Button> */}
            </DialogActions>
          </Dialog>
          <Dialog
            open={dialogConfirmationOpen}
            onClose={handleConfirmationClose}
          >
            <Container className="flex flex-col w-fit !place-content-evenly !justify-center !items-center bg-white p-4 rounded md:!w-[70%] sm:!w-[85%] xs:!w-full xss:!w-full ">
              <Row className="justify-center">
                <Col className="text-center">
                  <IconWithText
                    theme="icon-with-text-03"
                    data={IconWithTextData_03}
                    animation={fadeIn}
                    animationDelay={0.2}
                  />
                </Col>
              </Row>
            </Container>
          </Dialog>
        </m.section>
        {/* Section End */}

        {/* Section Start */}
        <section
          id="nasil-calisir"
          className="py-[8px] lg:py-[90px] md:py-[75px] sm:py-[50px] overflow-hidden startup-processstep"
        >
          <Container>
            <Row className="items-center justify-center">
              <Col xl={5} lg={6} md={10}>
                <h2 className="heading-5 font-serif font-semibold text-[#19876A] block -tracking-[.5px] mb-20 w-[85%] lg:mb-[80px] md:mb-12 md:w-[80%] sm:w-full">
                  Araç Yatırım Süreci Nasıl İşliyor?
                </h2>
                <ProcessStep
                  grid="row-cols-1"
                  theme="process-step-style-02"
                  data={ProcessStepData}
                  animation={fadeIn}
                />
              </Col>
              <m.div
                className="offset-xl-1 col-lg-6 md:mt-[50px]"
                {...fadeInLeft}
              >
                <div className="-mr-[30vw] relative md:mr-0">
                  <img
                    src="/assets/img/logo/experimental-how-much-earn.webp"
                    width={809}
                    height={538}
                    className="overflow-hidden"
                    alt=""
                  />
                </div>
              </m.div>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Info Banner Style 02 */}
        <m.section
          id="aracimi-kiraya-ver"
          className="py-[140px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] relative bg-cover bg-center"
          style={{ backgroundImage: "url(/assets/img/logo/mb.webp)" }}
        >
          <div className="absolute top-0 left-0 w-full h-full opacity-70 bg-darkslateblue"></div>
          <Container className="relative">
            <Row className="justify-center">
              <Col xl={6} lg={7} className="text-center mb-[4.5rem] md:mb-12">
                <h4 className="font-serif font-semibold text-white">
                  Kira gelirinizi hesaplayın
                </h4>
                <span className="font-serif mb-[5px] -tracking-[.5px] text-white block">
                  Aracınızı kiralayarak her ay ne kadar gelir elde
                  edebileceğinizi hesaplayın.
                </span>
              </Col>
              <Col className="col-xl-10 col-sm-12 col-xs-12">
                <Container className="flex flex-col w-fit !place-content-evenly !justify-center !items-center bg-white p-4 rounded mt-4 md:!w-[70%] sm:!w-[85%] xs:!w-full xss:!w-full ">
                  <Container className="flex flex-row ml-0 mr-0 mb-4 p-0 mt-12 md:!items-center md:!flex-col md:!mb-0">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      className="ml-2 mr-2 !w-fit min-w-[200px] rounded md:!mt-5 md:!w-full"
                      options={brandModelFromApi}
                      onChange={(_, value) => {
                        setSelectedBrand(value);
                        setSelectedClass();
                        const ele = classRef.current.getElementsByClassName(
                          "MuiAutocomplete-clearIndicator"
                        )[0];
                        if (ele) ele.click();
                      }}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Marka" />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      className="ml-2 mr-2 !w-fit min-w-[200px] rounded md:!mt-5 md:!w-full"
                      id="combo-box-demo"
                      options={classOptions}
                      ref={classRef}
                      disabled={!selectedBrand?.classes}
                      onChange={(_, value) => {
                        setSelectedClass(value);
                        setSelectedModel();
                        const ele = modelRef.current.getElementsByClassName(
                          "MuiAutocomplete-clearIndicator"
                        )[0];
                        if (ele) ele.click();
                      }}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Sınıf" />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      className="ml-2 mr-2 !w-fit min-w-[200px] rounded md:!mt-5 md:!w-full"
                      id="combo-box-demo"
                      ref={modelRef}
                      options={modelOptions}
                      disabled={!selectedClass}
                      sx={{ width: 300 }}
                      onChange={(_, value) => {
                        setSelectedModel(value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Model" />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      className="ml-2 mr-2 !w-fit min-w-[200px] rounded md:!mt-5 md:!w-full"
                      id="combo-box-demo"
                      options={[
                        { label: "Benzin", id: 1 },
                        { label: "Benzin & LPG", id: 2 },
                        { label: "Dizel", id: 3 },
                        { label: "Hybrid", id: 4 },
                        { label: "Elektrik", id: 5 },
                      ]}
                      onChange={(_, value) => {
                        setSelectedFuelType(value?.id);
                      }}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Yakıt Tipi" />
                      )}
                    />
                  </Container>
                  <Container className="flex !w-fit m-0 p-0 flex-row md:!items-center md:!flex-col md:!w-full">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      className="ml-2 mr-2 !w-fit min-w-[200px] rounded md:!mt-5 md:!w-full"
                      options={[
                        { label: "Manuel", id: 1 },
                        { label: "Otomatik", id: 2 },
                      ]}
                      onChange={(_, value) => {
                        setSelectedGearType(value?.id);
                      }}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Vites Tipi" />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      className="ml-2 mr-2 !w-fit min-w-[200px] rounded md:!mt-5 md:!w-full"
                      id="combo-box-demo"
                      options={[
                        { label: "2024", id: 1 },
                        { label: "2023", id: 2 },
                        { label: "2022", id: 3 },
                        { label: "2021", id: 4 },
                      ]}
                      onChange={(_, value) => {
                        setSelectedYear(value?.id);
                      }}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Yıl" />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      className="ml-2 mr-2 !w-fit min-w-[200px] rounded md:!mt-5 md:!w-full"
                      id="combo-box-demo"
                      options={[
                        { label: "Sedan", id: 1 },
                        { label: "Hatchback 5 kapı", id: 2 },
                        { label: "Hatchback 3 kapı", id: 3 },
                        { label: "Coupe", id: 4 },
                        { label: "Coupe 4 kapı", id: 5 },
                        { label: "Cabrio", id: 6 },
                      ]}
                      sx={{ width: 300 }}
                      onChange={(_, value) => {
                        setSelectedBodyType(value?.id);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Kasa Tipi" />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      className="ml-2 mr-2 !w-fit min-w-[200px] rounded md:!mt-5 md:!w-full"
                      id="combo-box-demo"
                      options={[
                        { label: "0 - 15.000", id: 1 },
                        { label: "15.000 - 40.000", id: 2 },
                        { label: "40.000 - 60.000", id: 3 },
                      ]}
                      sx={{ width: 300 }}
                      onChange={(_, value) => {
                        setSelectedKilometer(value?.id);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Kilometre" />
                      )}
                    />
                  </Container>

                  {/* <Container className=" justify-start">
                  <div className="mt-4 flex !w-fit justify-start align-left">
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked} // Checkbox'ın seçili olup olmadığını kontrol et
            onChange={handleChange} // Checkbox'ın durumu değiştiğinde handleChange fonksiyonunu çağır
            size="medium"
             sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          />
        }
        label="Kurumsal Müşteriyim"
        labelPlacement="end"
      />
    </div>
                  </Container> */}
                  <Container className="mt-4 flex justify-center !w-fit m-0 p-0 flex-row md:!items-center md:!flex-col md:!w-full">
                    <MUIButton
                      className="!bg-[#19876A] !border-green-600 disabled:!bg-gray-300 disabled:!text-gray-600 disabled:!border-gray-400 hover:!bg-[#19AA6A] !text-white !ml-2 !mr-2 mx-[10px] font-medium font-serif uppercase rounded !min-w-[200px] border-[2px] border-solid md:!mb-4 md:!mt-8 md:!w-full"
                      size="lg"
                      onClick={() => handleCalculationForCompany()}
                      disabled={
                        !selectedBrand ||
                        !selectedClass ||
                        !selectedModel ||
                        !selectedBodyType ||
                        !selectedFuelType ||
                        !selectedGearType ||
                        !selectedKilometer
                      }
                      variant="outlined"
                    >
                      ŞİRKET İÇİN Hesapla
                    </MUIButton>

                    <MUIButton
                      className="!bg-[#19876A] !border-green-600 disabled:!bg-gray-300 disabled:!text-gray-600 disabled:!border-gray-400 hover:!bg-[#19AA6A] !text-white !ml-2 !mr-2 mx-[10px] font-medium font-serif uppercase rounded !min-w-[200px] border-[2px] border-solid md:!mb-4 md:!mt-4 md:!w-full"
                      size="lg"
                      onClick={() => handleCalculation()}
                      disabled={
                        !selectedBrand ||
                        !selectedClass ||
                        !selectedModel ||
                        !selectedBodyType ||
                        !selectedFuelType ||
                        !selectedGearType ||
                        !selectedKilometer
                      }
                      variant="outlined"
                    >
                      ŞAHIS İÇİN Hesapla
                    </MUIButton>
                  </Container>

                  <Container>
                    <Row className="justify-center">
                      <Col>
                        <div className="h-px mt-4 mb-12 bg-[#cfcfcf] md:hidden "></div>
                      </Col>
                    </Row>
                  </Container>
                  <Container className="ml-auto w-fit md:mb-12">
                    <h6
                      id="labelResult"
                      className="text-center text-xl font-serif text-black mb-0"
                    ></h6>
                    <h3
                      id="result"
                      className="text-5xl text-center font-serif text-[#19876A] mb-0"
                    ></h3>
                  </Container>
                </Container>
              </Col>
            </Row>
          </Container>
        </m.section>
        {/* Info Banner Style 02 End */}

        {/* Section Start */}
        <section
          id="hakkimizda"
          className="bg-cover bg-[#f7f8fc] bg-center pb-[200px] lg:pb-[160px] md:py-[110px] sm:py-[50px] startup-iconwithtext"
        >
          <Container>
            <div className="mb-[105px] md:mb-[70px] sm:mb-[50px]">
              <Overlap className="md:mt-0">
                <Row className="justify-center">
                  <Col xs={12} sm={9} lg={12} md={12}>
                    <IconWithText
                      grid="row-cols-1 row-cols-lg-3 row-cols-md-2 justify-center gap-y-10 z-10 relative"
                      className="rounded-[4px] flex"
                      theme="icon-with-text-04"
                      data={iconWithTextData}
                    />
                  </Col>
                </Row>
              </Overlap>
            </div>
            <Row className="items-end overflow-hidden">
              <m.div
                className="col-12 col-lg-3 col-md-6 order-1 text-right md:mb-[50px] md:text-center"
                {...fadeInRight}
              >
                <div className="text-[70px] text-[#19876A] font-serif leading-[70px] tracking-[-3px] font-semibold">
                  175+
                </div>
                <span className="font-serif text-darkgray font-medium uppercase tracking-[2px] block mb-[15px] sm:mb-[5px]">
                  Mutlu YATIRIMCI
                </span>
                <p className="w-[90%] inline-block sm:w-[60%] xs:w-full">
                  Folya Filo,araç satın alma ve kiralama sektörüne yeni,müşteri
                  odaklı bir perspektif sunmayı amaçlayan araç kiralama
                  firmasıdır
                </p>
              </m.div>
              <m.div
                className="col col-lg-6 order-lg-2 order-3 z-0 py-[10px]"
                {...{ ...fadeIn, transition: { duration: 0.6 } }}
              >
                <TiltBox>
                  <h1 className=" text-[#19876A] font-serif inline-block font-serif mb-0 uppercase font-semibold tracking-[-10px] text-[300px] lg:text-[300px] leading-[260px] xs:text-[160px] xs:leading-[150px]">
                    10
                  </h1>
                  <span className="font-serif text-xlg text-darkgray tracking-[4px] font-semibold uppercase block xs:text-md">
                    YILLIK SEKTÖREL TECRÜBE
                  </span>
                </TiltBox>
              </m.div>
              <m.div
                className="col-12 col-lg-3 col-md-6 order-2 md:mb-[50px] md:text-center"
                {...fadeInLeft}
              >
                <div className="text-[70px] text-[#19876A] font-serif leading-[70px] tracking-[-3px] font-semibold">
                  100+
                </div>
                <span className="font-serif text-darkgray font-medium uppercase tracking-[2px] block mb-[15px] sm:mb-[5px]">
                  KİRADAKİ ARAÇ SAYISI
                </span>
                <p className="w-[90%] inline-block sm:w-[60%] xs:w-full">
                  Deneyimimize, dinamizmimize ve yeteneklerimize güveniyor;
                  sizin için en kârlı araç kiralama ortaklığını sunmayı
                  vadediyoruz.
                </p>
              </m.div>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <section
          id="aracim-yatirimi"
          className="bg-lightgray py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
        >
          <Container>
            <Row className="items-center justify-center">
              <m.div
                className="col col-xl-4 col-lg-5 col-md-10 md:mb-20"
                {...fadeIn}
              >
                <h2 className="heading-5 font-serif text-[#2f2f2f] font-bold tracking-[-1px]">
                  Araç yatırımı yaparak{" "}
                  <span className="text-[#289474]">yıllık ne kadar gelir</span>{" "}
                  elde edeceksiniz ?
                </h2>
                <blockquote className="border-l-[4px] text-darkgray font-medium border-[#8bb867] text-xmd pl-[25px] pr-0 mt-[40px] mb-[30px] lg:w-[95%]">
                  {" "}
                  Araba satın alarak düzenli araç kiralama geliri elde
                  edebilirsiniz. Folya Filo’ya ulaşın, mali durumunuza ve
                  beklentilerinize göre en doğru araç yatırımını yapın.
                </blockquote>
                <p className="w-[90%] mb-[25px] md:w-full">
                  Aylık tahmini kira gelirinizi hesaplamak için yatırım
                  tutarınızı girin.
                </p>
                <TextField
                  className="w-[90%]"
                  hiddenLabel
                  id="filled-hidden-label-small"
                  size="small"
                  placeholder="Yatırım Tutarı"
                  value={investmentAmount}
                  onChange={(e) => setInvestmentAmount(e.target.value)}
                />
                <MUIButton
                  onClick={() => handleInvestmentCalculation()}
                  className="!mt-4 !bg-[#19876A] text-white w-[90%]"
                  variant="outlined"
                  size="medium"
                >
                  ŞAHIS İÇİN Hesapla
                </MUIButton>

                <MUIButton
                  onClick={() => handleInvestmentCalculationForCompany()}
                  className="!mt-4 !bg-[#19876A] text-white w-[90%]"
                  variant="outlined"
                  size="medium"
                >
                  ŞİRKET İÇİN Hesapla
                </MUIButton>
                <p id="yatirim-sonuc" className="w-[90%]   md:w-full"></p>
              </m.div>
              <m.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="col-12 col-md-10 col-lg-7 offset-xl-1"
              >
                {/* <Area chartData={chartDetail} ></Area> */}
                <div className="area">
                  <Chart
                    options={investmenetChartDetails.options}
                    series={investmenetChartDetails.series}
                    type="area"
                  />
                </div>
              </m.div>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <section
          id="avantajlar"
          className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] bg-[#f7f8fc]"
        >
          <Container>
            <Row className="justify-center">
              <m.div
                className="col-xl-6 col-lg-7 col-sm-8 mb-20 text-center w-[51%] xl:mb-[70px] lg:mb-[65px] md:mb-[60px] sm:mb-[55px] md:w-[68%] xs:w-full"
                {...fadeIn}
              >
                <h4 className="w-full mb-[20px] font-semibold text-darkgray font-serif -tracking-[1px]">
                  Folya Filo ile düzenli kira geliri elde edin.{" "}
                </h4>
                <span className="inline-block font-serif text-md mb-[20px] font-medium  text-[#19876A] xs:mb-[15px]">
                  Arabanızı kiraya vererek düzenli gelir elde edebileceğinizi
                  biliyor muydunuz? Siz de doğru araç yatırımını yaparak her ay
                  yüksek kazançlar elde edebilirsiniz. Folya Filo’ya ulaşın,
                  bütçenizi belirleyin ve hemen kazanmaya başlayın
                </span>
              </m.div>
            </Row>
            <Row className="justify-center">
              <Col lg={12} md={9} xs={12}>
                <IconWithText
                  grid="row-cols-1 row-cols-lg-2 gap-y-10 justify-center"
                  theme="icon-with-text-02"
                  data={IconWithTextData_02}
                  animation={fadeIn}
                  animationDelay={0.2}
                />
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <m.section
          className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] bg-lightgray"
          {...fadeIn}
        >
          <h6 className="font-serif text-gray-900 text-center font-medium mb-24 xs:mb-16">
            {" "}
          </h6>
          <Container className="md:!w-[70%] sm:!w-full">
            <Row className="justify-center">
              <TestimonialsCarousel02
                className="slider-nav-style-01 slider-nav-dark black-move"
                title="Müşteri"
                subtitle="yorumları"
                data={TestimonialsCarouselData2}
                carouselOption={{
                  slidesPerView: 1,
                  loop: true,
                  navigation: true,
                  autoplay: { delay: 5000, disableOnInteraction: false },
                }}
              />
            </Row>
          </Container>
        </m.section>
        {/* Section End */}

        {/* Section Start */}
        <section className="bg-darkgray">
          <m.section className="py-20 bg-lightgray" id="sikca-sorulan-sorular">
            <Container>
              <Row className="justify-center">
                <Col lg={8} md={10}>
                  <h2 className="heading-4 font-serif font-semibold text-[#19876A] text-center mb-[65px] xs:mb-[30px]">
                    Sıkça Sorulan Sorular
                  </h2>

                  <Accordion
                    theme="accordion-style-02"
                    className=""
                    // animation={zoomIn}
                    themeColor="light"
                    data={AccordionData02.slice(0, visibleItems)}
                  />
                  <div className="text-center mt-4">
                    <MUIButton
                      className="!bg-[#19876A] hover:!bg-[#19AA6A] text-white w-[40%] !item-center"
                      variant="outlined"
                      onClick={showMoreItems}
                    >
                      Daha Fazla Yükle
                    </MUIButton>
                  </div>
                </Col>
              </Row>
            </Container>
          </m.section>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <section
          id="iletisim"
          className="py-[80px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
        >
          <Container>
            <Row className="justify-center">
              <Col md={10} lg={7} className="text-center">
                <span className="font-serif font-medium text-[#19876A] text-xmd mb-[20px] inline-block sm:mb-[10px]">
                  - FOLYA FİLO -
                </span>
                <h2 className="heading-3 font-serif font-semibold text-darkgray inline-block text-center mb-[65px] xs:mb-[30px]">
                  Yardıma ihtiyacınız mı var? Bize ulaşın{" "}
                </h2>
              </Col>
            </Row>
            <Row className="justify-center">
              <Col className="col-12 col-lg-5 col-md-8 md:mb-[50px]">
                <GoogleMap
                  className="grayscale h-[500px] xs:h-[300px]"
                  location="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3586.315766672343!2d29.216518349392064!3d40.899957695655836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac5158cf45df5%3A0x17836df1ab30e15!2sFolya%20Filo%20Kiralama%20A.%C5%9E!5e0!3m2!1str!2str!4v1701440823309!5m2!1str!2str"
                />
              </Col>
              <Col className="col-12 col-lg-6 offset-lg-1 col-md-8">
                {/* <h4 className="font-serif text-black font-semibold">Let's get in touch with us</h4> */}
                <Row className="justify-center">
                  <Col>
                    <TextField
                      id="outlined-error"
                      label="Ad Soyad"
                      variant="standard"
                      className="pt-[20px] pr-[36px] pb-[20px] w-full bg-transparent border-b border-solid border-black text-[20px] !mb-8"
                    />
                    <TextField
                      id="outlined-error"
                      label="Telefon Numarası"
                      variant="standard"
                      className="pt-[20px] pr-[36px] pb-[20px] w-full bg-transparent border-b border-solid border-black text-[20px] !mb-8"
                    />
                    <TextField
                      id="outlined-error"
                      label="Email Adresi"
                      variant="standard"
                      className="pt-[20px] pr-[36px] pb-[20px] w-full bg-transparent border-b border-solid border-black text-[20px] !mb-8"
                    />
                    <TextField
                      id="standard-multiline-static"
                      label="Konu"
                      multiline
                      rows={7}
                      variant="standard"
                      className="pt-[20px] pr-[36px] pb-[20px] w-full bg-transparent border-b border-solid border-black text-[20px] !mb-20"
                    />
                    <MUIButton
                      className="!bg-[#19876A] hover:!bg-[#19AA6A] text-white w-[40%] item-center"
                      variant="outlined"
                    >
                      Gönder
                    </MUIButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section End */}
        {/* Footer Start */}

        <Footer theme="dark" className="text-[#828282] bg-darkgray">
          <div className="pt-[6%] lg:pt-[8%] footer-menu xs:py-[50px]">
            <Container>
              <Row className="justify-between md:text-start gap-y-[60px] w-full">
                <Col
                  lg={{ span: 3, order: 0 }}
                  md={6}
                  sm={{ span: 6, order: 1 }}
                >
                  <Link
                    aria-label="link"
                    to="/"
                    className="mb-[30px] inline-block xs:mb-[20px]"
                  >
                    <img
                      src="/assets/img/logo/logo.png"
                      alt="logo"
                      width="35"
                      height="34"
                      className="w-auto"
                    />
                  </Link>
                  <p className="subtitle font-serif font-light ml-8 w-[85%] lg:w-full md:w-[70%] sm:w-full text-[20px] leading-[36px] -tracking-[0.5px] xs:w-[90%]">
                    Folya Filo ile Yatırımlarınız Yollarda Değer Kazansın!
                  </p>
                </Col>
                <div class="footer-menu justify-between  mt-2 col-lg-auto col-sm-6 order-lg-0 order-sm-3">
                  <span class="mb-[20px] block font-medium font-serif xs:!mb-[10px] uppercase traking-[2px]">
                    SİTE Haritası
                  </span>
                  <ul>
                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                      <ScrollTo
                        to="avantajlar"
                        offset={80}
                        delay={0}
                        spy={true}
                        smooth={true}
                        duration={800}
                        className="inner-link nav-link"
                      >
                        Avantajlar
                      </ScrollTo>
                    </li>
                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                      <ScrollTo
                        to="nasil-calisir"
                        offset={80}
                        delay={0}
                        spy={true}
                        smooth={true}
                        duration={800}
                        className="inner-link nav-link"
                      >
                        Araç Yatırımı
                      </ScrollTo>
                    </li>
                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                      <ScrollTo
                        to="aracimi-kiraya-ver"
                        offset={80}
                        delay={0}
                        spy={true}
                        smooth={true}
                        duration={800}
                        className="inner-link nav-link"
                      >
                        Aracımı kiraya ver
                      </ScrollTo>
                    </li>
                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                      <ScrollTo
                        to="arac-kirala"
                        offset={80}
                        delay={0}
                        spy={true}
                        smooth={true}
                        duration={800}
                        className="inner-link nav-link"
                      >
                        Kurumsal Araç Kiralama{" "}
                      </ScrollTo>
                    </li>
                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                      <ScrollTo
                        to="sikca-sorulan-sorular"
                        offset={80}
                        delay={0}
                        spy={true}
                        smooth={true}
                        duration={800}
                        className="inner-link nav-link"
                      >
                        Sıkça Sorulan Sorular
                      </ScrollTo>
                    </li>
                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                      <ScrollTo
                        to="hakkimizda"
                        offset={80}
                        delay={0}
                        spy={true}
                        smooth={true}
                        duration={800}
                        className="inner-link nav-link"
                      >
                        Hakkımızda
                      </ScrollTo>
                    </li>
                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                      <ScrollTo
                        to="iletisim"
                        offset={80}
                        delay={0}
                        spy={true}
                        smooth={true}
                        duration={800}
                        className="inner-link nav-link"
                      >
                        İletişim
                      </ScrollTo>
                    </li>
                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                      <Link
                        to="/gizlilik-politikasi" // Yeni sayfa yolu
                        className="inner-link nav-link"
                      >
                        Gizlilik Sözleşmesi
                      </Link>
                    </li>
                  </ul>
                </div>
                <div class="footer-menu justify-between mt-8 mt-2 col-lg-auto col-sm-6 order-lg-0 order-sm-3">
                  <ul>
                    <span className="mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]">
                      İLETİŞİM
                    </span>
                    <div>
                      <i className="feather-navigation text-sm mr-[10px] text-themecolor"></i>
                      <a href="https://www.google.com.tr/maps/dir/40.9141248,29.2159488/Folya+Filo+Kiralama+A.%C5%9E+Kartal/@40.9069169,29.2030521,15z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14cac5158cf45df5:0x17836df1ab30e15!2m2!1d29.2186121!2d40.8997099?hl=tr-TR&entry=ttu">
                        Yalı Mah. Topselvi Cad. B Blok Mai Rezidans NO : 100B/81
                        Kartal İstanbul
                      </a>
                    </div>
                    <div>
                      <i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>
                      <a href="tel:+908507625909">0850 762 59 09</a>
                    </div>
                    <div>
                      <i className="feather-mail text-sm mr-[10px] text-themecolor"></i>
                      <a aria-label="mail" href="mailTo:info@folyafilo.com">
                        info@folyafilo.com
                      </a>
                    </div>
                  </ul>
                </div>
              </Row>
            </Container>
          </div>
          <div className="py-[6%] xs:pt-0 xs:p-0 footer-details">
            <Container>
              <Row>
                <Col
                  sm={{ span: 12, order: 2 }}
                  md={{ span: 6, order: 1 }}
                  xs={{ order: 2 }}
                  className="text-start flex sm:justify-center md:text-end md:mb-4 xs:justify-start"
                >
                  <p>
                    &copy; {new Date().getFullYear()} Folya Filo Tüm Hakları
                    Saklıdır.{" "}
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </Footer>
      </InViewPort>
      {/* Footer End */}
    </div>
  );
};

export default HomeStartupPage;
