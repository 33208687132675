import axios from "axios";

export default class ApiService {
  // INSERT URL
  //baseURL = "https://localhost:7027";
  baseURL = "https://api.folyafilo.com";
  failureInterceptor = (error) => {
    return Promise.reject(error);
  };

  createAxiosInstance = (contentType = "application/json") => {
    const instance = axios.create({
      headers: {
        /* Authorization: `Bearer ${"INSERT TOKEN HERE"}`, */
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": contentType, //'multipart/form-data',
      },
      validateStatus: (status) =>
        status >= 200 && status <= 500 && status !== 401 && status !== 400,
      baseURL: this.baseURL,
    });

    instance.interceptors.response.use(
      (response) => response,
      this.failureInterceptor
    );
    return instance;
  };

  get = (url = "", ...params) => {
    const axiosInstance = this.createAxiosInstance();
    return axiosInstance.get(this.baseURL + url, ...params);
  };

  post(url = "", ...params) {
    if (params && params[1]?.ContentType) {
      const axiosInstance = this.createAxiosInstance(params[1].ContentType);
      return axiosInstance.post(this.baseURL + url, ...params);
    } else {
      const axiosInstance = this.createAxiosInstance();
      return axiosInstance.post(this.baseURL + url, ...params);
    }
  }

  put(url = "", ...params) {
    if (params && params[1]?.ContentType) {
      const axiosInstance = this.createAxiosInstance(params[1].ContentType);
      return axiosInstance.put(this.baseURL + url, ...params);
    } else {
      const axiosInstance = this.createAxiosInstance();
      return axiosInstance.put(this.baseURL + url, ...params);
    }
  }

  delete(url = "", ...params) {
    const axiosInstance = this.createAxiosInstance();
    return axiosInstance.delete(this.baseURL + url, ...params);
  }

  patch(url = "", ...params) {
    const axiosInstance = this.createAxiosInstance();
    return axiosInstance.patch(this.baseURL + url, ...params);
  }
}
